import { useFormik } from "formik";
import React, { useEffect } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteCatalog,
  fetchCatalogs,
  getCatalog,
  setEditId,
} from "../../app/reducers/Catalog/catalogSlice";
import { Edit2, Trash } from "react-feather";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { useCallback } from "react";
import { useMemo } from "react";
import EditCatalogModel from "./EditCatalogModel";
import { Navigate, useNavigate } from "react-router-dom";
import {
  fetchCategories,
  getCategories,
} from "../../app/reducers/Category/categorySlice";
import {
  getJobWork,
  fetchJobWorks,
} from "../../app/reducers/JobWork/jobWorkSlice";
import { getUsers, fetchUsers } from "../../app/reducers/Users/userSlice";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { exportCsv, generateOptions } from "../../utils/Utils";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import FormikCheckbox from "../../components/formik/FormikCheckbox";
import {
  fetchProducts,
  getProduct,
} from "../../app/reducers/Product/productSlice";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { catalogStatus } from "../../utils/dropdownOptions";
const ViewCatalog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      manufacturer: "",
      category: "",
      defaultJobName: "",
      isEmbroidery: false,
      status: [catalogStatus[0].value],
    },
    onSubmit: (values) => {
      let filter = {};
      if (values.manufacturer) {
        filter["manufacturer"] = values.manufacturer;
      }
      if (values.category) {
        filter["category"] = values.category;
      }
      if (values.defaultJobName) {
        filter["defaultJobName"] = values.defaultJobName;
      }
      if (values.isEmbroidery) {
        filter["isEmbroidery"] = values.isEmbroidery;
      }
      if (values.status) {
        filter["isActive"] = { $in: values.status };
      }
      dispatch(fetchCatalogs({ populate: true, ...filter }));
    },
  });
  useEffect(() => {
    // dispatch(fetchCatalogs({ populate: true, limit: 300, sort: { name: 1 } }));
    dispatch(fetchJobWorks());
    dispatch(fetchUsers({ role: "manufacturer" }));
    dispatch(fetchCategories());
    dispatch(fetchProducts({ populate: true }));
    let filter = {};
    if (formik.values.manufacturer) {
      filter["manufacturer"] = formik.values.manufacturer;
    }
    if (formik.values.category) {
      filter["category"] = formik.values.category;
    }
    if (formik.values.defaultJobName) {
      filter["defaultJobName"] = formik.values.defaultJobName;
    }
    if (formik.values.isEmbroidery) {
      filter["isEmbroidery"] = formik.values.isEmbroidery;
    }
    if (formik.values.status) {
      filter["isActive"] = { $in: formik.values.status };
    }
    dispatch(
      fetchCatalogs({
        populate: true,
        ...filter,
        limit: 300,
        sort: { name: 1 },
      })
    );
  }, []);

  const { loading, catalog } = useSelector(getCatalog);
  const { jobWork } = useSelector(getJobWork);
  const { category } = useSelector(getCategories);
  const { users } = useSelector(getUsers);
  const { product } = useSelector(getProduct);
  const jobWorkdata = useMemo(
    () => (jobWork?.docs ? jobWork.docs : []),
    [jobWork]
  );

  const categorydata = useMemo(
    () => (category?.docs ? category.docs : []),
    [category]
  );

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "Catalog Name",
      accessor: "name",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <a
            style={{ cursor: "pointer" }}
            href={`/viewProduct/${row.original._id}`}
          >
            <span style={{ color: "blue", textDecoration: "underline" }}>
              {row.original.name}
            </span>
          </a>
        );
      },
    },
    {
      Header: "Embroidery",
      Cell: (prop) => {
        const { data, row } = prop;
        return <span>{row.original.isEmbroidery ? "true" : "false"}</span>;
      },
    },
    {
      Header: "Manufacturer",
      accessor: "manufacturerData.name",
    },
    {
      Header: "Job Work",
      accessor: "defaultJobName",
    },
    {
      Header: "Number of Products",
      accessor: "projectCount",
    },
    {
      Header: "Action",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <div
            className="flex items-center gap-2"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            {" "}
            <Edit2
              color="blue"
              onClick={() => {
                dispatch(setEditId(row.original._id));
              }}
            />
            {row.original.projectCount === 0 && (
              <Trash
                color="red"
                onClick={async () => {
                  await dispatch(deleteCatalog({ id: row.original._id }));
                  dispatch(fetchCatalogs());
                }}
              />
            )}
          </div>
        );
      },
    },
  ];

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      dispatch(fetchJobWorks({ search: search }));
    }, 300),
    []
  );
  const data = useMemo(() => (catalog?.docs ? catalog.docs : []), [catalog]);
  const productData = useMemo(
    () => (product?.docs ? product.docs : []),
    [product]
  );

  const exportToCsv = (e) => {
    e.preventDefault();
    let upData = data.map((d) => {
      return {
        name: d.name,
        isEmbroidery: d.isEmbroidery,
        price: d.price,
        defaultJobName: d.defaultJobName,
        defaultJobPrice: d.defaultJobPrice,
        catalogId: d.catalogId,
      };
    });
    // return
    exportCsv(upData);
  };

  const exportToCsvProduct = (e) => {
    e.preventDefault();
    let upData = productData.map((d) => {
      return {
        color: d.color,
        catalog: d.catalogData?.name,
        price: d.price,
        image: d.image,
        sku: d.sku,
      };
    });
    // return
    exportCsv(upData);
  };

  return (
    <PageWithCard heading="View Catalog">
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-4">
        <FormikSelectGroup
          formik={formik}
          label="Manufacturer"
          name="manufacturer"
          options={generateOptions({
            array: users ? users.docs : [],
            valueField: "_id",
            labelField: "name",
          })}
          required
        />
        <FormikSelectGroup
          formik={formik}
          label="Category"
          name="category"
          options={generateOptions({
            array: categorydata,
            valueField: "_id",
            labelField: "name",
          })}
          required
        />
        <FormikSelectGroup
          formik={formik}
          label="Default Job Name"
          name="defaultJobName"
          options={generateOptions({
            array: jobWorkdata ? jobWorkdata : [],
            valueField: "name",
            labelField: "name",
          })}
          required
        />
        <FormikMultiSelect
          formik={formik}
          label="status"
          // onChange={async (selectedOption) => {
          //   console.log(selectedOption);
          // }}
          name="status"
          required
          options={catalogStatus}
        />

        <FormikCheckbox
          label="isEmbroidery"
          formik={formik}
          name="isEmbroidery"
          required
        />
        <div className="mb-3">
          <PrimaryButton type="submit">Submit</PrimaryButton>
        </div>
      </form>
      <div className="mt-2 mb-2 flex gap-3">
        <PrimaryButton onClick={exportToCsv} style={{ marginBottom: "10px" }}>
          Export Catalog
        </PrimaryButton>
        <PrimaryButton
          onClick={exportToCsvProduct}
          style={{ marginBottom: "10px" }}
        >
          Export Product
        </PrimaryButton>
      </div>
      <TableWithHeadingAndSearch
        heading="Catalogs"
        data={data}
        columns={columns}
        loading={loading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
      <EditCatalogModel />
    </PageWithCard>
  );
};

export default ViewCatalog;
