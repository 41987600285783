import React from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Image,
} from "@react-pdf/renderer";
import moment from "moment";
import numWords from "num-words";

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    backgroundColor: "#fff",
    paddingBottom: 15,
    paddingTop: 15,
  },
  saleSection: {
    padding: 10,
    paddingTop: 22,
    textAlign: "center",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
  },
  smallText: {
    fontSize: 10,
  },
  mediumText: {
    fontSize: 12,
  },
  largeText: {
    fontSize: 12,
    fontWeight: "extrabold",
  },
  topSection: {
    width: "100%",
    border: "1px solid black",
    padding: 10,
    display: "flex",
    flexDirection: "row",
  },
  midSection: {
    width: "100%",
    borderBottom: "1px solid black",
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
    display: "flex",
    flexDirection: "row",
  },

  section1_2: {
    width: 297,
  },
  sectionBill: {
    width: 357,
    borderRight: "1px solid black",
    padding: 10,
  },
  sectionBill2: {
    width: 238,
    padding: 10,
  },
  sectionList1: {
    paddingLeft: 10,
    paddingRight: 10,
    width: 40,
    paddingTop: 3,
    paddingBottom: 3,
    borderLeft: "1px solid black",
    borderRight: "1px solid black",
  },
  sectionList2: {
    paddingLeft: 10,
    paddingRight: 10,
    paddingTop: 3,
    paddingBottom: 3,
    width: 200,
    borderRight: "1px solid black",
  },
  sectionList3: {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    width: 75,
    borderRight: "1px solid black",
  },
  sectionList4: {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    width: 45,
    borderRight: "1px solid black",
  },
  sectionList5: {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    width: 65,
    borderRight: "1px solid black",
  },
  sectionList6: {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    width: 90,
    borderRight: "1px solid black",
  },
  sectionList7: {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    width: 53,
    borderRight: "1px solid black",
  },
  sectionList8: {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    width: 52,
    borderRight: "1px solid black",
  },
  sectionList9: {
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    width: 80,
    borderRight: "1px solid black",
  },

  margin: {
    marginTop: 5,
  },
  image_container: {
    width: 297,
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
  },
  image: {
    width: 100,
    marginLeft: 147,
  },
});

export const BillDocument = ({ bill }) => (
  <Document>
    <Page size={[595, 420]} style={styles.page}>
      <View style={styles.saleSection}>
        <Text style={styles.smallText}>Sale Invoice</Text>
      </View>
      <View style={styles.topSection}>
        <View style={styles.section1_2}>
          <Text style={styles.largeText}>TRENDY CULTURE</Text>
          <Text style={[styles.smallText, styles.margin]}>
            {bill.location?.name},
          </Text>
          <Text style={[styles.smallText, styles.margin]}>
            {bill.location?.address}
          </Text>
          <Text style={[styles.smallText, styles.margin]}>
            Contact Number :
            {bill.location?.phones[0] ? `+${bill.location.phones[0]}` : "---"}
          </Text>
          <Text style={[styles.smallText, styles.margin]}>
            GST No. : 24AAQFT6868H1ZH
          </Text>
        </View>
        <View style={styles.image_container}>
          {/* <Image style={styles.image} src={EyesdealLogo} /> */}
          <Text>Trendy Culture</Text>
        </View>
      </View>
      <View style={styles.midSection}>
        <View style={styles.sectionBill}>
          <Text style={styles.largeText}>Party Name : {bill.customerName}</Text>
          <Text style={[styles.smallText, styles.margin]}>
            Contact Number : +{bill.customerPhone}
          </Text>
        </View>
        <View style={styles.sectionBill2}>
          <Text style={styles.smallText}>Sale Number :{bill.saleNumber}</Text>
          <Text style={{ fontSize: 10, marginTop: 10 }}>
            Date : {moment(bill.createdAt).format("DD/MM/YYYY")}
          </Text>
        </View>
      </View>
      <View style={styles.midSection}>
        <View style={styles.sectionList1}>
          <Text style={styles.mediumText}>Sr</Text>
        </View>
        <View style={styles.sectionList2}>
          <Text style={styles.mediumText}>Catalog Name</Text>
        </View>
        <View style={styles.sectionList3}>
          <Text style={styles.mediumText}>Color</Text>
        </View>
        <View style={styles.sectionList4}>
          <Text style={styles.mediumText}>Qty</Text>
        </View>
        <View style={styles.sectionList5}>
          <Text style={styles.mediumText}>Selling Price</Text>
        </View>
        <View style={styles.sectionList6}>
          <Text style={styles.mediumText}>Discount</Text>
        </View>
        <View style={styles.sectionList7}>
          <Text style={styles.mediumText}>CGST</Text>
        </View>
        <View style={styles.sectionList8}>
          <Text style={styles.mediumText}>SGST</Text>
        </View>
        <View style={styles.sectionList9}>
          <Text style={styles.mediumText}>Amount</Text>
        </View>
      </View>
      {bill.product?.map?.((ele, i) => (
        <View style={styles.midSection} key={i}>
          <View style={styles.sectionList1}>
            <Text style={styles.largeText}>{i + 1}</Text>
          </View>
          <View style={styles.sectionList2}>
            <Text style={styles.mediumText}>{ele.catalog_id.name}</Text>
          </View>
          <View style={styles.sectionList3}>
            <Text style={styles.mediumText}>{ele.product_id.color}</Text>
          </View>
          <View style={styles.sectionList4}>
            <Text style={styles.largeText}>{ele.qty}</Text>
          </View>
          <View style={styles.sectionList5}>
            <Text style={styles.mediumText}>
              {(0.95 * parseInt(ele.sellPrice)).toFixed(2)}
            </Text>
          </View>
          <View style={styles.sectionList6}>
            <Text style={styles.mediumText}>{ele.price - ele.sellPrice}</Text>
          </View>
          <View style={styles.sectionList7}>
            <Text style={styles.mediumText}>
              {(0.025 * parseInt(ele.sellPrice)).toFixed(2)}
            </Text>
          </View>
          <View style={styles.sectionList8}>
            <Text style={styles.mediumText}>
              {(0.025 * parseInt(ele.sellPrice)).toFixed(2)}
            </Text>
          </View>
          <View style={styles.sectionList9}>
            <Text style={styles.largeText}>{ele.qty * ele.sellPrice}</Text>
          </View>
        </View>
      ))}

      <View style={styles.topSection}>
        <View style={styles.section1_2}>
          <Text style={styles.mediumText}>Amount In Words</Text>
          <Text style={[styles.smallText, styles.margin]}>
            {numWords(
              bill.receivedAmount?.reduce((acc, val) => acc + val.amount, 0)
            )}
          </Text>
        </View>
        <View style={styles.image_container}>
          <Text style={styles.smallText}>
            Total Amount :{" "}
            {bill.receivedAmount?.reduce((acc, val) => acc + val.amount, 0)}
          </Text>
        </View>
      </View>
    </Page>
  </Document>
);
