import React, { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchSales, getSale } from "../../app/reducers/Sale/saleSlice";
import { useEffect } from "react";
import { authAxiosInstance } from "../../utils/axiosConfig";
import { useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import { toast } from "react-toastify";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { useMemo } from "react";
import { useFormik } from "formik";
import FormikInputDateGroup from "../../components/formik/FormikInputDateGroup";
import moment from "moment";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import QueryString from "qs";
import _ from "lodash";
import { exportCsv, generateOptions } from "../../utils/Utils";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import { limitVar } from "../../utils/limitVariable";

export const SalesReport = () => {
  const [salesData, setSalesData] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [totalSalesAmount, setTotalSalesAmount] = useState({});
  const { location, loading: locationsLoading } = useSelector(getLocation);
  const dispatch = useDispatch();

  useEffect(() => {
    getSalesReport();
    getSalesAmount();
    dispatch(fetchLocations());
  }, [page]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: [],
      startDate: moment().startOf("day").valueOf(),
      endDate: moment().endOf("day").valueOf(),
    },
    onSubmit: async (values) => {
      console.log(values);
      try {
        setLoading(true);
        const data = {
          createdAt: {
            $gte: values?.startDate,
            $lte: values?.endDate,
          },
          "location._id": { $in: values?.location },
          populate: true,
          page,
        };
        const string = QueryString.stringify(data);
        const respSales = await authAxiosInstance.get(
          `/report/sales?${string}`
        );
        if (respSales) {
          setSalesData(respSales?.data?.data);
        }
        const respSaleAmount = await authAxiosInstance.get(
          `/report/sales-amount?${string}`
        );
        if (respSaleAmount?.data?.data?.docs?.length > 0) {
          const sumAmount = respSaleAmount?.data?.data?.docs;
          sumAmount?.forEach((el) => setTotalSalesAmount(el));
        } else {
          setTotalSalesAmount({});
        }
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Failed to fetch sales");
      } finally {
        setLoading(false);
      }
    },
  });

  const getSalesReport = async (search) => {
    try {
      setLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        "location._id": { $in: formik?.values?.location },
        populate: true,
        page,
        limit: 100,
      };
      if (search) {
        data.search = search;
      }
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(`/report/sales?${string}`);
      setSalesData(resp?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to fetch sales");
    } finally {
      setLoading(false);
    }
  };

  const getSalesAmount = async () => {
    try {
      setLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        "location._id": { $in: formik?.values?.location },
        populate: true,
        page,
        limit: 100,
      };
      // if (search) {
      //   data.search = search;
      // }
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(
        `/report/sales-amount?${string}`
      );

      if (resp?.data?.data?.docs?.length > 0) {
        const sumAmount = resp?.data?.data?.docs;
        sumAmount?.forEach((el) => setTotalSalesAmount(el));
      } else {
        setTotalSalesAmount({});
      }
    } catch (error) {
      console.log(error);
      toast.error("Failed to Sum Total Sales Amount");
    }
  };

  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        console.log(row, "row");
        return row.index + salesData?.pagingCounter;
      },
    },
    // {
    //   Header: "Date",
    //   Cell: ({ row }) => {
    //     return moment(row?.original?.createdAt).format("DD/MM/YYYY");
    //   },
    // },
    {
      Header: "Color",
      accessor: "product.color",
    },
    {
      Header: "Catalog Name",
      accessor: "catalog.name",
    },
    {
      Header: "SKU",
      accessor: "product.sku",
    },
    {
      Header: "Total Sale",
      accessor: "totalQuantity",
    },
    {
      Header: "Effective Sale",
      Cell: ({ row }) => {
        return <>₹ {row?.original?.totalSaleAmount}</>;
      },
      //   accessor: `₹ totalSaleAmount`,
    },
  ];

  const downloadExportCsv = async () => {
    try {
      setLoading(true);
      const data = {
        createdAt: {
          $gte: formik.values.startDate,
          $lte: formik.values.endDate,
        },
        limit: limitVar,
        populate: true,
      };
      const string = QueryString.stringify(data);
      const resp = await authAxiosInstance.get(`/report/sales?${string}`);
      if (resp?.data?.data?.docs?.length > 0) {
        const csvData = resp?.data?.data?.docs?.map((el) => {
          return {
            Color: el?.product?.color,
            "Catalog Name": el?.catalog?.name,
            SKU: el?.product?.sku,
            "Total Sale": el?.totalQuantity,
            "Effective Sale": el?.totalSaleAmount,
          };
        });
        exportCsv(csvData);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Failed to Download");
    } finally {
      setLoading(false);
    }
  };

  const dataMemo = useMemo(
    () => (salesData?.docs ? salesData?.docs : []),
    [salesData?.docs]
  );
  const columnsMemo = useMemo(() => columns, [columns]);
  const locationData = useMemo(
    () => (location?.docs ? location.docs : []),
    [location]
  );

  const debouncedSearch = useCallback(
    _.debounce((search) => {
      console.log("searching");
      getSalesReport(search);
    }, 300),
    [JSON.stringify(salesData)]
  );

  return (
    <PageWithCard heading="Sales Report">
      <form
        onSubmit={formik.handleSubmit}
        className="grid grid-cols-1 gap-2 m-2"
      >
        <FormikMultiSelect
          label="Location"
          formik={formik}
          name="location"
          options={generateOptions({
            array: locationData,
            valueField: "_id",
            labelField: "name",
          })}
        />
        <FormikInputDateGroup
          label="Start Date"
          name="startDate"
          formik={formik}
        />
        <FormikInputDateGroup label="End Date" name="endDate" formik={formik} />
        <div className="flex flex-row gap-2">
          <PrimaryButton type="submit">Submit</PrimaryButton>
          <PrimaryButton type="button" onClick={downloadExportCsv}>
            Download
          </PrimaryButton>
        </div>
      </form>
      {/* {totalSalesAmount && ( */}
      <div className="text font-semibold mb-2 flex flex-row gap-2">
        <p>
          Total Effective Sale : <span>{totalSalesAmount?.totalSrp ?? 0}</span>
        </p>
        <p>
          Total Sale Qty : <span>{totalSalesAmount?.totalQty ?? 0}</span>
        </p>
      </div>
      {/* )} */}
      <TableWithHeadingAndGlobalSearch
        heading="Sales Report"
        data={dataMemo}
        columns={columnsMemo}
        loading={loading}
        searchFunction={(value) => {
          debouncedSearch(value);
        }}
      />
      <PaginationClassic
        paginationDetails={{
          ...salesData,
        }}
        setPage={setPage}
      />
    </PageWithCard>
  );
};
