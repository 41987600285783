import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import PageWithCard from "../../components/infrastructure/PageWithCard";
import FormikInputGroup from "../../components/formik/FormikInputGroup";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import PrimaryButton from "../../components/infrastructure/Buttons/PrimaryButton";
import TableWithHeadingAndGlobalSearch from "../../components/Table/TableWithHeadingAndGlobalSearch";
import { useCallback } from "react";
import {
  fetchLocations,
  getLocation,
} from "../../app/reducers/Location/locationSlice";
import FormikSelectGroup from "../../components/formik/FormikSelectGroup";
import { generateOptions } from "../../utils/Utils";
import QueryString from "qs";
import { authAxiosInstance } from "../../utils/axiosConfig";
import FormikMultiSelect from "../../components/formik/FormikMultiSelect";
import PaginationClassic from "../../components/pagination/PaginationClassic";
import TableWithHeadingAndSearch from "../../components/Table/TableWithHeadingAndSearch";
import { ClipLoader } from "react-spinners";

const LowStock = () => {
  const dispatch = useDispatch();
  const [inventoryData, setInventoryData] = useState({});
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState(1);

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const { location } = useSelector(getLocation);
  const locationInfoId = localStorage.getItem("locationInfoId");

  useEffect(() => {
    if (formik.values.location && formik.values.quantity) {
      formik.submitForm();
    }
  }, [page]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: [],
      quantity: null,
    },
    validationSchema: Yup.object({
      location: Yup.array(),
      quantity: Yup.number().required(),
    }),
    onSubmit: async (values) => {
      setLoading(true);
      const string = QueryString.stringify({
        page,
        limit: 950,
        populate: true,
        location: {
          $in: values.location,
        },
        finished: {
          $lte: parseInt(values.quantity),
        },
      });
      const respData = await authAxiosInstance.get(
        `productInventory/product?${string}`
      );
      if (respData?.data?.data) {
        setInventoryData(respData.data?.data);
        setLoading(false);
      } else {
        alert("Something Went Wrong");
        setLoading(false);
      }
    },
  });
  const columns = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return inventoryData.pagingCounter + row.index;
      },
    },
    {
      Header: "Catalog",
      accessor: "catalogData.name",
    },
    {
      Header: "Product",
      accessor: "productData.color",
    },
    {
      Header: "Stock/Finished",
      accessor: "finished",
    },
  ];
  const debouncedSearch = useCallback(
    _.debounce(async (search) => {
      let data = {
        populate: true,
        // search: search,
      };
      if (search) {
        data.search = search;
      }
      if (formik.values.location) {
        data["location"] = {
          $in: formik.values.location,
        };
      }
      if (formik.values.quantity) {
        data["finished"] = {
          $lte: parseInt(formik.values.quantity),
        };
      }
      const string = QueryString.stringify(data);

      const respData = await authAxiosInstance.get(
        `productInventory/product?${string}`
      );
      if (respData?.data?.data) {
        setInventoryData(respData.data?.data);
        setLoading(false);
      } else {
        alert("Something Went Wrong");
        setLoading(false);
      }
    }, 300),
    [JSON.stringify(inventoryData)]
  );

  return (
    <>
      <PageWithCard heading="Low Stock">
        <form
          onSubmit={formik.handleSubmit}
          className="flex flex-col gap-4 mb-2"
        >
          <FormikMultiSelect
            formik={formik}
            label="Location"
            name="location"
            required
            options={generateOptions({
              array: location ? location.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
          />
          <FormikInputGroup
            label="Quantity"
            type="number"
            formik={formik}
            name="quantity"
            required
          />
          <div>
            <PrimaryButton type="submit">Submit</PrimaryButton>
          </div>
        </form>

        {loading ? (
          <ClipLoader />
        ) : (
          // <TableWithHeadingAndSearch
          //   heading="Inventory Detail"
          //   data={inventoryData?.docs ? inventoryData.docs : []}
          //   loading={loading}
          //   columns={columns}
          // />

          <TableWithHeadingAndGlobalSearch
            heading="Inventory Detail"
            data={inventoryData?.docs ? inventoryData.docs : []}
            loading={loading}
            columns={columns}
            searchFunction={(value) => {
              debouncedSearch(value);
            }}
          />
        )}

        <PaginationClassic
          paginationDetails={inventoryData}
          setPage={setPage}
        />
      </PageWithCard>
    </>
  );
};

export default LowStock;
