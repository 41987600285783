import React, { useState } from "react";
import { useCallback } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TableWithHeadingAndGlobalSearch from "../components/Table/TableWithHeadingAndGlobalSearch";
import { authAxiosInstance } from "../utils/axiosConfig";
import { Link } from "react-router-dom";
import QueryString from "qs";
import { useDispatch, useSelector } from "react-redux";
import { generateOptions } from "../utils/Utils";
import FormikSelectGroup from "../components/formik/FormikSelectGroup";
import {
  fetchLocations,
  getLocation,
} from "../app/reducers/Location/locationSlice";
import { useFormik } from "formik";
import TableWithHeadingAndSearch from "../components/Table/TableWithHeadingAndSearch";
import FormikMultiSelect from "../components/formik/FormikMultiSelect";
import ShowDropdown from "../components/infrastructure/ShowDropdown";
import ADMIN_ROLES from "../utils/roles";

const Dashboard = () => {
  const [greyQualityCount, setGreyQualityCount] = useState();
  const [productCount, setProductCount] = useState();
  const [greyQualityData, setGreyQualityData] = useState();
  const [productInventoryData, setProductInventoryData] = useState();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const locationInfoId = localStorage.getItem("locationInfoId");

  useEffect(() => {
    dispatch(fetchLocations());
  }, []);

  const { location } = useSelector(getLocation);

  const debouncedSearch = useCallback(
    _.debounce(async (search) => {
      const string = QueryString.stringify({
        populate: true,
        location: formik.values.location,
        limit: 300,
        search,
      });
      const productData = await authAxiosInstance.get(
        `productInventory/catalog?${string}`
      );
      if (productData) {
        setProductInventoryData(productData.data?.data?.docs);
      }
    }, 300),
    []
  );
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      location: locationInfoId ? [locationInfoId] : [],
    },
    onSubmit: async (values) => {
      // formik.resetForm()
    },
  });

  useEffect(async () => {
    const overallString = QueryString.stringify({
      locationArr: formik.values.location,
    });
    const string = QueryString.stringify({
      populate: true,
      location: { $in: formik.values.location },
      limit: 300,
    });
    const result = await authAxiosInstance.get(`dashboard?${overallString}`);
    if (result) {
      setGreyQualityCount(result.data?.data?.greyQuality);
      setProductCount(result.data?.data?.productCount);
      setGreyQualityData(result.data?.data?.greyQualityData);
    }

    const productData = await authAxiosInstance.get(
      `productInventory/catalog?${string}`
    );
    if (productData) {
      setProductInventoryData(productData.data?.data?.docs);
    }
  }, [JSON.stringify(formik.values)]);

  const colsMemo = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },

    {
      Header: "Grey Quality",
      accessor: "greyQualityData.name",
      Cell: ({ row }) => {
        const locationString = formik.values.location.join(",");

        return (
          <Link
            to={`/greyInventory/internal/${row.original.greyQualityData._id}/${locationString}`}
          >
            <div className="text-indigo-600 underline">
              {row.original.greyQualityData.name}
            </div>
          </Link>
        );
      },
    },
    {
      Header: "Unit",
      accessor: "greyQualityData.unit",
    },
    {
      Header: "Pending",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original?.greyQuality?.unit === "pcs"
              ? row.original.pending
              : row.original.pending?.toFixed(2)}
          </span>
        );
      },
    },
    {
      Header: "Received",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original?.greyQuality?.unit === "pcs"
              ? row.original.received
              : row.original.received?.toFixed(2)}
          </span>
        );
      },
    },
    {
      Header: "Pending Print",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original?.greyQuality?.unit === "pcs"
              ? row.original.pendingPrint
              : row.original.pendingPrint?.toFixed(2)}
          </span>
        );
      },
    },
    {
      Header: "Pending Embroidery",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original?.greyQuality?.unit === "pcs"
              ? row.original.pendingGreyEmbroidery
              : row.original.pendingGreyEmbroidery?.toFixed(2)}
          </span>
        );
      },
    },
    // {
    //   Header: "Finished Print",
    //   Cell: (prop) => {
    //     const { data, row } = prop;
    //     return (
    //       <span>
    //         {row.original.greyQuality.unit === "pcs"
    //           ? row.original.finishedPrint
    //           : row.original.finishedPrint.toFixed(2)}
    //       </span>
    //     );
    //     return;
    //   },
    // },
    {
      Header: "Damaged",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original.greyQuality?.unit === "pcs"
              ? row.original.damaged
              : row.original.damaged?.toFixed(2)}
          </span>
        );
      },
    },
    {
      Header: "Shortage",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <span>
            {row.original.greyQuality?.unit === "pcs"
              ? row.original.shortage
              : row.original.shortage?.toFixed(2)}
          </span>
        );
      },
    },
  ];
  const colsProductMemo = [
    {
      Header: "SRNO",
      Cell: (prop) => {
        const { data, row } = prop;
        return row.index + 1;
      },
    },
    {
      Header: "catalog",
      accessor: "catalogData.name",
      Cell: (prop) => {
        const { data, row } = prop;
        return (
          <a
            style={{ cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(
                `/view-stock/${formik.values.location}/${row.index + 1}`
              );
            }}
          >
            <span
              style={{
                textDecoration: "underline",
                color:
                  row.original.catalogData?.isActive == false ? "red" : "blue",
              }}
            >
              {row.original?.catalogData?.name}
            </span>
          </a>
        );
      },
    },
    {
      Header: "Pending",
      accessor: "pending",
    },

    {
      Header: "Unfinished",
      accessor: "unfinished",
    },
    {
      Header: "Pending Unfinished Embroidery",
      accessor: "pendingUnfinishedEmbroidery",
    },
    {
      Header: "Unfinished Embroidery",
      accessor: "unfinishedEmbroidery",
    },
    {
      Header: "Pending Job Work",
      accessor: "pendingJobWork",
    },
    {
      Header: "finished",
      accessor: "finished",
    },
    {
      Header: "Sold",
      accessor: "sold",
    },
    {
      Header: "In Transfer",
      accessor: "inTransfer",
    },
    {
      Header: "damaged",
      accessor: "damaged",
    },
  ];

  return (
    <div className="px-4 sm:px-6 lg:px-8 py-8 w-full max-w-9xl mx-auto">
      {/* Page header */}
      <div className="mb-8">
        {/* Title */}
        <h1 className="text-2xl md:text-3xl text-slate-800 font-bold">
          Dashboard
        </h1>
      </div>

      {/* Content */}
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4">
          <FormikMultiSelect
            formik={formik}
            label="Location"
            onChange={async (selectedOption) => {
              const data = {
                populate: true,
                location: { $in: selectedOption.map((ele) => ele.value) },
                limit: 300,
              };
              if (selectedOption.length === 0) {
                alert(
                  "No location will show overall stock in unexpected manner"
                );
              }
              const string = QueryString.stringify(data);

              const productData = await authAxiosInstance.get(
                `productInventory/catalog?${string}`
              );
              if (productData) {
                setProductInventoryData(productData.data?.data?.docs);
              }
              formik.setFieldValue(
                `location`,
                selectedOption.map((ele) => ele.value)
              );
            }}
            name="location"
            required
            options={generateOptions({
              array: location ? location.docs : [],
              valueField: "_id",
              labelField: "name",
            })}
          />
        </div>
        <>
          <div
            className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
            style={{ width: "250px" }}
          >
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Total Grey Pending</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {greyQualityCount &&
                  greyQualityCount.length &&
                  greyQualityCount.map((d, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{d._id}</div>
                      {d._id == "meter" ? d.pending?.toFixed(2) : d.pending}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div
            className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
            style={{ width: "250px" }}
          >
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Total Grey</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {greyQualityCount &&
                  greyQualityCount.length &&
                  greyQualityCount.map((d, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{d._id}</div>
                      {d._id == "meter" ? d.received?.toFixed(2) : d.received}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div
            className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
            style={{ width: "250px" }}
          >
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">
                Total Grey Pending Print
              </header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {greyQualityCount &&
                  greyQualityCount.length &&
                  greyQualityCount.map((d, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{d._id}</div>
                      {d._id == "meter"
                        ? d.pendingPrint?.toFixed(2)
                        : d.pendingPrint}
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <div
            className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
            style={{ width: "250px" }}
          >
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Total Grey Damaged</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {greyQualityCount &&
                  greyQualityCount.length &&
                  greyQualityCount.map((d, i) => (
                    <div className="flex-col items-center text-lg" key={i}>
                      <div>{d._id}</div>
                      {d._id == "meter" ? d.damaged?.toFixed(2) : d.damaged}
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </>
        <>
          <div
            className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
            style={{ width: "250px" }}
          >
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Total Pending</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productCount && (
                  <div className="flex-col items-center text-lg">
                    {productCount.pending}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
            style={{ width: "250px" }}
          >
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Total Unfinished</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productCount && (
                  <div className="flex-col items-center text-lg">
                    {productCount.unfinished}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
            style={{ width: "250px" }}
          >
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Total Finished</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productCount && (
                  <div className="flex-col items-center text-lg">
                    {productCount.finished}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div
            className="max-w-sm  border overflow-hidden shadow-lg text-center mb-3 rounded-lg"
            style={{ width: "250px" }}
          >
            <div className="px-3 pt-3 mb-5">
              <header className="text-xl font-bold">Total Damaged</header>
              <div className="flex items-center justify-evenly px-1 pt-3">
                {productCount && (
                  <div className="flex-col items-center text-lg">
                    {productCount.damaged}
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </div>
      <ShowDropdown heading="Grey Inventory" allowedRoles={ADMIN_ROLES.ALL}>
        {greyQualityData && (
          <TableWithHeadingAndSearch
            heading="Grey Inventory"
            data={greyQualityData}
            columns={colsMemo}
          />
        )}
      </ShowDropdown>

      <div style={{ marginTop: "15px" }}>
        <div style={{ marginTop: "15px" }}>
          {productInventoryData && (
            <TableWithHeadingAndSearch
              heading="Product Inventory"
              data={productInventoryData}
              columns={colsProductMemo}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
